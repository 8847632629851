import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import axios from 'axios';

// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Box,
    Grid,
} from '@mui/material';
// components
import DeleteIcon from '@mui/icons-material/Delete';
import ModalDemo from '../../layouts/modal';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { ResidentListHead, ResidentListToolbar } from '../../sections/@dashboard/residents';
// mock
import invoiceList from '../../_mock/user';
import { fetchInvoiceList } from '../../services/invoiceService';
import InvoiceListToolBar from './InvoiceListToolBar';

//----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


export default function InvoicePage() {

    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [invoiceList, setinvoiceList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t, i18n } = useTranslation();
    const { status } = useParams();
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedType, setSelectedType] = useState("-1");

    const user = useSelector((state) => state.auth.user);


    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("selectedType", selectedType)) {
            setSelectedType(localStorage.getItem("selectedType", selectedType));
          }

        const fetchData = async () => {
            try {
                const data = await fetchInvoiceList(user.idCustomer);
                data.sort((a,b) => (a.documentDate < b.documentDate) ? 1 : ((b.documentDate < a.documentDate) ? -1 : 0))
                setinvoiceList(data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchData();
    }, [user]);

    const STATUS_OPTIONS = [
        { label: t('invoice-list-status-encoded'), color: 'primary', value: 1 },
        { label: t('invoice-list-status-to-pay'), color: 'secondary', value: 2 },
        { label: t('invoice-list-status-paid'), color: 'success', value: 3 },
        { label: t('invoice-list-status-partially-paid'), color: 'warning', value: 6 },
        { label: t('invoice-list-status-first-reminder'), color: 'warning', value: 4 },
        { label: t('invoice-list-status-second-reminder'), color: 'warning', value: 5 },
        { label: t('invoice-list-status-third-reminder'), color: 'error', value: 8 },
        { label: t('invoice-list-status-cancelled'), color: 'info', value: 7 },
    ];
    
    const TABLE_HEAD = [
        // { id: 'number', label: 'Numéro', alignRight: false },
        { id: 'date', label: t('invoice-list-date-invoice'), alignRight: false },
        { id: 'directInvoicingInfo', label: t('invoice-list-resident'), alignRight: false},
        { id: 'reference', label: t('invoice-list-reference'), alignRight: false },
        { id: 'amount', label: t('invoice-list-amount'), alignRight: false },
        { id: 'dueDate', label: t('invoice-list-due-date'), alignRight: false },
        { id: 'statut', label: t('invoice-list-status'), alignRight: false },
    ];

    function applySortFilter(array, comparator, query, type) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
            return filter(array, (_invoice) => {
                const yearMatch = _invoice.year ? _invoice.year.toString().indexOf(query.toLowerCase()) !== -1 : false;
                const numberMatch = _invoice.no ? _invoice.no.toString().indexOf(query.toLowerCase()) !== -1 : false;
                const dateMatch = _invoice.documentDate ? _invoice.documentDate.toString().indexOf(query.toLowerCase()) !== -1 : false;
                const residentMatch = _invoice.directInvoicingInfo ? _invoice.directInvoicingInfo.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false;
                let typeMatch;

                if(type === "-1")
                {
                    typeMatch = true;
                }
                else if (type === "0")
                {
                    typeMatch = !_invoice.directInvoicingInfo || (_invoice.directInvoicingInfo && _invoice.directInvoicingInfo === "" )
                }
                else if (type === "1")
                {
                    typeMatch = _invoice.directInvoicingInfo && _invoice.directInvoicingInfo !== "";
                }

                return typeMatch && (yearMatch || numberMatch || dateMatch || residentMatch);
            });
    }


    const getStatusByID = (status) => {
        switch (status) {
            case 1:
                return [t('invoice-list-status-encoded'), "primary"];
            case 2:
                return [t('invoice-list-status-to-pay'), "secondary"];
            case 3:
                return [t('invoice-list-status-paid'), "success"];
            case 4:
                return [t('invoice-list-status-first-reminder'), "warning"];
            case 5:
                return [t('invoice-list-status-second-reminder'), "warning"];
            case 6:
                return [t('invoice-list-status-partially-paid'), "warning"];
            case 7:
                return [t('invoice-list-status-cancelled'), "info"];
            case 8:
                return [t('invoice-list-status-third-reminder'), "error"];
            default:
                return [t('invoice-list-status-unknown-status'), "info"];
        }
    }

    const handleStatusToggle = (status) => {
        if (selectedStatus.includes(status)) {
            setSelectedStatus((prevSelected) => prevSelected.filter((s) => s !== status));
        } else {
            setSelectedStatus((prevSelected) => [...prevSelected, status]);
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getDelay = (date) => {
        const date1 = new Date(date);
        const date2 = new Date();
        const diffTime = date2 - date1;
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = invoiceList.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleRowClick = (event, id) => {
        navigate(`/invoices/${id}`);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleFilterType = (event) => {
        setPage(0);
        setFilterName(filterName);
        setSelectedType(event.target.value);
        localStorage.setItem("selectedType", event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoiceList.length) : 0;
    const filteredUsers = applySortFilter(invoiceList, getComparator(order, orderBy), filterName, selectedType);
    const isNotFound = !filteredUsers.length && !!filterName;

    return (
        <>
            <Helmet>
                <title> {t('invoice-list-invoices')} </title>
            </Helmet>

            <Box sx={{ height: '100%' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" >
                    <Typography variant="h4" gutterBottom>
                        {t('invoice-list-invoices')}
                    </Typography>
                </Stack>

                <Grid container justifyContent="flex-start" spacing={0.5} sx={{ pb: 2 }}>
                    {STATUS_OPTIONS.map((statusOption) => (
                        (statusOption.value !== 1 && statusOption.value !== 7) &&
                        <Grid item key={statusOption.value}>
                            <Button
                                variant={selectedStatus.includes(statusOption.value) ? 'contained' : 'outlined'}
                                onClick={() => handleStatusToggle(statusOption.value)}
                                color={statusOption.color}
                                sx={{ width: '100%', forcedColorAdjust: 'white' }}
                                mb={1}>
                                {statusOption.label}
                            </Button>
                        </Grid>
                    ))}
                </Grid>


                <Card>
                    <InvoiceListToolBar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} selected={selected} setSelected={setSelected} invoiceList={invoiceList} setinvoiceList={setinvoiceList} selectedType={selectedType} setSelectedType={handleFilterType}/>

                    <Scrollbar>
                        <TableContainer sx={{}}>
                            <Table>
                                <ResidentListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={invoiceList.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                    hideCheckBox={1}
                                />
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                                        const { id, guid, documentDate, directInvoicingInfo, reference, no, year, totalPriceTaxIncl, dueDate, status } = row;
                                        const selectedUser = selected.indexOf(id) !== -1;
                                        const delay = getDelay(row.dueDate);
                                        const statusstr = getStatusByID(row.status);
                             
                                        if ((selectedStatus.length > 0 && selectedStatus.includes(status)) || selectedStatus.length === 0) {
                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                    selected={selectedUser}
                                                    onClick={(event) => {
                                                        if (event.target.tagName !== 'INPUT') {
                                                            handleRowClick(event, guid);
                                                        }
                                                    }}>

                                                    <TableCell align="left">
                                                        <Label color={'primary'}>{`${year}-${zeroPad(no, 3)}`}</Label>
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {documentDate ? format(new Date(documentDate), "dd/MM/yyyy", { locale: frLocale }) : ""}
                                                    </TableCell>
                                                    
                                                    <TableCell align="left">
                                                        {directInvoicingInfo}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {reference}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        <Label>
                                                            {totalPriceTaxIncl.toLocaleString('fr-FR', {
                                                                style: 'currency',
                                                                currency: 'EUR',
                                                            })}
                                                        </Label>
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {dueDate ? format(new Date(dueDate), "dd/MM/yyyy", { locale: frLocale }) : ""}
                                                        {delay > 0 && status !== 3 ? (<span style={{ fontWeight: 'bold' }}>{` (${delay} ${t('invoice-list-day-late')})`}</span>) : ""}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        <Label color={statusstr[1]}>{statusstr[0]}</Label>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }
                                        return (<></>);
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                                                <Paper sx={{ textAlign: 'center' }}>
                                                    <Typography variant="h6" paragraph>
                                                    {t('user-list-not-found')}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                    {t('invoice-list-no-invoice-found')}&nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> {t('user-list-verify-your-search')}
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={invoiceList.length}
                        rowsPerPage={rowsPerPage}
                        labelRowsPerPage={t('user-list-result-per-page')}
                        labelDisplayedRows={(page) =>
                            `${page.from}-${page.to === -1 ? page.count : page.to} ${t('on-result')} ${page.count
                            }`
                        }
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Box>

        </>
    );
}